@import './variables.scss';
@import './layout.scss';

@import './utilities/mixins';
@import './utilities/accessibility';

@each $name, $color in $theme-colors {
    .bg-#{$name} {
        background-color: $color !important;
    }
}