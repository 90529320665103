/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Ubuntu:wght@400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import './styles/main.scss';

*{
    font-family: Ubuntu, Montserrat, sans-serif;
    // font-family: Poppins, Ubuntu, Montserrat, sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html{
    font-size: 16px;
    color: $text-color;
}