/*
* Cores
*/
$text-color: #333333;

$color-primary: #285ba6;
$color-secondary: #9677c6;
$color-info: #3176FB;
$color-success: #169D49;
$color-danger: #ff4848;
$color-warning: #d77813;

$theme-colors: (
    primary: $color-primary,
    secondary: $color-secondary,
    info: $color-info,
    success: $color-success,
    danger: $color-danger,
    warning: $color-warning,
);

$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #bbbbbb;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__link: #004c8f;
$color__link-visited: #004c8f;
$color__link-hover: #d77813;
$color__text-main: #1d1d1b;

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;

/**/

$border-radius: 0.25rem;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    xs: 100%,
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);
