@use "sass:map";
@import "/src/styles/utilities/mixins";
@import "/src/styles/variables";

@each $name, $width in $grid-breakpoints {
  @media (min-width: $width) { 
    .container {
      width: map.get($container-max-widths, #{$name});
      margin: auto;
      padding-inline: 1rem;
    }
  }
}
